/* eslint-disable react/no-unescaped-entities */
import { Anchor, Box, Center, Container, Divider, List, Text, Title } from '@mantine/core'

import { DEVICES } from '@/constants/devices'
import FormCalculate from '@/components/form'

const Root = () => {
  return (
    <Container>
      <Box className="cd-mt-[4rem]">
        <Center>
          <Title order={1}>
            Welcome to{' '}
            <Text inherit span c="green">
              Battery health{' '}
            </Text>
            calculator!
          </Title>
        </Center>
      </Box>
      <FormCalculate />
      <Divider mt="4rem" />
      <Box className="cd-pt-[2rem]">
        <Title c="white" order={2}>
          Devices
        </Title>
        <Text size="md">
          Currently, we have the following devices in our database. If you don't find your device,
          you can use the "Cycle per percentage" input:
        </Text>
        <List className="!cd-mt-[0.5rem] !cd-ml-[1.5rem]" listStyleType="disc">
          {DEVICES.map((device) => (
            <List.Item key={device.id}>
              <Text size="lg">
                {device.name} - {device.cyclesPerPercentage} cycles per percentage
              </Text>
            </List.Item>
          ))}
        </List>
      </Box>
      <Box className="cd-pt-[2rem]">
        <Title c="white" order={2}>
          How battery health is calculated?
        </Title>
        <Text size="md">
          The battery health is calculated by dividing the number of cycles by the number of cycles
          per percentage of your device. For example, if your device has 500 cycles and each 25
          cycles the battery percentage decreases by 1%, then the battery health is 20%.
        </Text>
      </Box>
      <Box className="cd-pt-[2rem]">
        <Title c="white" order={2}>
          Why is important?
        </Title>
        <Text size="md">
          Knowing the battery health of your device is important because it helps you to know when
          you should replace your battery. Also, it helps you to know if the battery health is
          affecting the performance of your device.
        </Text>
      </Box>
      <Center className="cd-py-[4rem]">
        <Text size="md">
          Made with ❤️ by{' '}
          <Anchor
            c="green"
            href="https://enmanuelmag.cardor.dev"
            rel="noopener noreferrer"
            target="_blank"
          >
            Emanuel Magallanes
          </Anchor>
        </Text>
      </Center>
    </Container>
  )
}

export default Root
//1169.7
