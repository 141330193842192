import { DeviceType } from '@/types/data'

export const DEVICES: DeviceType[] = [
  {
    id: 'iphone-14',
    name: 'iPhone 14',
    description:
      'The iPhone 14 is the standard iPhone in the iPhone 14 lineup. It features a 6.1-inch Super Retina XDR display, a dual-camera system, and the A16 Bionic chip.',
    cyclesPerPercentage: 25,
    releaseDate: '2022-09-16',
  },
  {
    id: 'iphone-14-pro',
    name: 'iPhone 14 Pro',
    description:
      'The iPhone 14 Pro is the latest and greatest iPhone from Apple. It features a 6.1-inch Super Retina XDR display, a triple-camera system, and the A16 Bionic chip.',
    cyclesPerPercentage: 25,
    releaseDate: '2022-09-16',
  },
  {
    id: 'iphone-14-pro-max',
    name: 'iPhone 14 Pro Max',
    description:
      'The iPhone 14 Pro Max is the larger version of the iPhone 14 Pro. It features a 6.7-inch Super Retina XDR display, a triple-camera system, and the A16 Bionic chip.',
    cyclesPerPercentage: 25,
    releaseDate: '2022-09-16',
  },
]
