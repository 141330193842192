import { Route, Routes, BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryCache, MutationCache, QueryClientProvider } from '@tanstack/react-query'
import { useMantineColorScheme } from '@mantine/core'

import { ROUTES } from '@src/constants/routes'

//Private
import Root from '@src/pages/root'

import './styles.scss'

export const queryClient = new QueryClient({
  queryCache: new QueryCache(),
  mutationCache: new MutationCache(),
})

const App = () => {
  useMantineColorScheme({
    keepTransitions: true,
  })

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route element={<Root />} path={ROUTES.ROOT} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export default App
