import zod from 'zod'

/* USER SPOTIFY */
export const UserSchema = zod.object({
  id: zod.string(),
  name: zod.string(),
})

export type UserType = zod.infer<typeof UserSchema>

/* Device */
export const DeviceSchema = zod.object({
  id: zod.string(),
  name: zod.string(),
  description: zod.string(),
  cyclesPerPercentage: zod.number(),
  releaseDate: zod.string(),
})

export type DeviceType = zod.infer<typeof DeviceSchema>

export const FormCalculatorSchema = zod.object({
  device: DeviceSchema.nullable(),
  cycles: zod.number().gt(0, 'Cycles must be greater than 0'),
  cyclesPerPercentage: zod
    .number()
    .gt(0, 'Cycles per percentage must be greater than 0')
    .nullable(),
})

export type FormCalculatorType = zod.infer<typeof FormCalculatorSchema>
