/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import {
  Button,
  Center,
  Divider,
  NumberInput,
  Paper,
  Select,
  Text,
  Transition,
} from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'

import { DeviceType, FormCalculatorSchema, FormCalculatorType } from '@/types/data'
import { DEVICES } from '@/constants/devices'

const FormCalculate = () => {
  const [health, setLife] = React.useState<number | null>(null)

  const form = useForm<FormCalculatorType>({
    validate: zodResolver(FormCalculatorSchema),
    initialValues: {
      cycles: 0,
      device: null,
      cyclesPerPercentage: null,
    },
  })

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Paper withBorder className="cd-mt-[4rem] sm:cd-mx-[0rem] lg:cd-mx-[6rem]" p="xl">
      <form onSubmit={form.onSubmit(({ cycles, device }) => calculateLife(cycles, device))}>
        <NumberInput
          className="cd-mb-[2rem]"
          label="Number of cycles"
          placeholder="Type the number battery's cycles"
          {...form.getInputProps('cycles')}
          value={form.values.cycles || undefined}
          onChange={(n) => form.setFieldValue('cycles', Number(n))}
        />
        <Select
          allowDeselect
          clearable
          searchable
          className="cd-mb-[1rem]"
          data={DEVICES.map((device) => ({ value: device.id, label: device.name }))}
          label="Select your device"
          placeholder="Type to search..."
          {...form.getInputProps('device')}
          value={form.values.device?.id || null}
          onChange={handleDeviceChange}
          onClear={() => {
            form.setFieldValue('device', null)
            form.setErrors({ device: undefined })
            form.setFieldValue('cyclesPerPercentage', null)
          }}
        />
        <Divider label="OR" my="md" />
        <NumberInput
          className="cd-mb-[2rem]"
          disabled={Boolean(form.values.device)}
          label="Cycle per percentage"
          placeholder="Type the number of cycles per percentage of your device"
          {...form.getInputProps('cyclesPerPercentage')}
          onChange={(n) => {
            form.setFieldValue('cyclesPerPercentage', Number(n))
            form.setErrors({ cyclesPerPercentage: undefined })
          }}
        />

        <Button
          fullWidth
          type="submit"
          variant="light"
          onClick={(e) => {
            e.preventDefault()
            const { device, cyclesPerPercentage } = form.values
            console.log(form.values)
            if (!device && !cyclesPerPercentage) {
              form.setErrors({
                device: !device ? 'Select a device' : undefined,
                cyclesPerPercentage: !cyclesPerPercentage ? 'Type the number of cycles' : undefined,
              })
              return
            }
            calculateLife(form.values.cycles, form.values.device, form.values.cyclesPerPercentage)
          }}
        >
          CALCULATE HEALTH
        </Button>
      </form>
      <Transition duration={750} mounted={Boolean(health)} timingFunction="ease" transition="fade">
        {(styles) => (
          <Center className="cd-w-full cd-mt-[2rem]" style={styles}>
            <Text className="cd-mt-[2rem]" size="xl">
              Your battery health should be{' '}
              <Text span c="green">
                {health}%
              </Text>
            </Text>
          </Center>
        )}
      </Transition>
    </Paper>
  )

  function handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      calculateLife(form.values.cycles, form.values.device)
    }
  }

  function handleDeviceChange(value: string | null) {
    if (!value) return
    const device = DEVICES.find((device) => device.id === value) || null
    form.setFieldValue('device', device)
  }

  function calculateLife(
    cycles: number | null,
    deviceSelected?: DeviceType | null,
    cyclesPerPercentage?: number | null,
  ) {
    console.log(cycles, deviceSelected, cyclesPerPercentage)
    if ((!deviceSelected && !cyclesPerPercentage) || !cycles) {
      return
    }

    const cycleXPer = deviceSelected?.cyclesPerPercentage || cyclesPerPercentage || 1

    const remainingPercentage = 100 - cycles / cycleXPer

    setLife(Number(remainingPercentage.toFixed(2)))
  }
}

export default FormCalculate
